import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(9),
      // marginLeft: theme.spacing(10),
    },
    titleTypo: {
      fontSize: '30px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      marginBottom: theme.spacing(3),
      cursor: 'pointer',
    },
    listTypo: {
      fontSize: '15px',
      fontFamily: ['Cabin', 'sans-serif'].join(','),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    table: {
      // width: '100%',
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
    editButton: {
      marginTop: theme.spacing(5),

      width: theme.spacing(20),
      height: theme.spacing(8),
    },
    createPostButton: {
      marginTop: theme.spacing(3),
    },
  })
)

export const useMobileStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(0),
      marginLeft: theme.spacing(3),
    },
    titleTypo: {
      fontSize: '30px',
      fontFamily: ['SourceSansPro', 'sans-serif'].join(','),
      marginBottom: theme.spacing(3),
    },
    listTypo: {
      fontSize: '15px',
      fontFamily: ['Cabin', 'sans-serif'].join(','),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
    },
    table: {
      // width: '100%',
      // minWidth: 650,
      // tableLayout: 'fixed',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(2),
      },
    },
    editButton: {
      marginTop: theme.spacing(5),

      width: theme.spacing(20),
      height: theme.spacing(8),
    },
    createPostButton: {
      marginTop: theme.spacing(3),
    },
  })
)
