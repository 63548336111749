import React from 'react'
import Grid from '@material-ui/core/Grid'
import ContentAdminSideMenu from '../../components/ContentAdmin/SideTableMain'
import CssBaseline from '@material-ui/core/CssBaseline'
import SnackBarNotification from '../Layout/SnackBar'
import Header from '../Layout/Header'

function LayoutFull({ children }) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      {/*<Container>*/}
      <div style={{ margin: 30 }}>
        <main>{children}</main>
      </div>
      {/*</Container>*/}
      {/*<Footer />*/}
    </React.Fragment>
  )
}

export default function AdminLayout({ children }) {
  return (
    <LayoutFull>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <ContentAdminSideMenu />
        </Grid>
        <Grid item xs={11}>
          {children}
        </Grid>
        <SnackBarNotification />
      </Grid>
    </LayoutFull>
  )
}
