import React from 'react'
import AdminStrategyTab from '../../components/ContentAdmin/StrategyTab'
import AdminLayout from '../../components/ContentAdmin'

export default () => {
  return (
    <AdminLayout>
      <AdminStrategyTab />
    </AdminLayout>
  )
}
