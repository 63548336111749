import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { useStyles } from './style'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'
import { useState } from '@hookstate/core'
import { tableStyles } from '../../../../styles/globalStyle/tableStyles'
import { EducationAction, EducationState } from '../../../Education/state'
import { ROUTES, CONTENT_ADMIN_ROUTES } from '../../../../constant'
import { StrategyAction, StrategyState } from '../../../Strategy/state'
import AdminStrategyListFooter from './footer'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import DeleteIcon from '@material-ui/icons/Delete'
import { getObj } from '../../../../utils/hookState'
import CONF from '../../../../config'

export default function AdminStrategyList() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const listState = useState(StrategyState.list)
  // const categoryState = useState(StrategyState.category)
  const listPageNoState = useState(StrategyState.listPageNo)

  useEffect(() => {
    StrategyAction.findAndCountEquity({}, false, listPageNoState.get(), CONF.cardFetchCount)()
  }, [listPageNoState.get()])

  // const tableHeaderList = ['Title', 'Type', 'Status', 'Edit', 'Active', 'Suspend', 'Delete']
  const tableHeaderList = ['Title', 'TitleVi', 'Type', 'Status']

  return (
    <Paper className={classes.paper} elevation={0}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeaderList.map((label) => (
              <TableCell key={label}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {getObj(listState.data).length > 0 &&
            getObj(listState.data).map((row: any, taskIndex) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.codeCell}
                  onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY_EDIT}/${row.id}`)}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.codeCell}
                  onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY_EDIT}/${row.id}`)}
                >
                  {row.titleVi}
                </TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.status}</TableCell>
                {/*<TableCell>*/}
                {/*  <EditIcon*/}
                {/*    className={classes.editButton}*/}
                {/*    onClick={() => navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.STRATEGY_EDIT}`)}*/}
                {/*  />*/}
                {/*</TableCell>*/}
                {/*<TableCell>*/}
                {/*  <CheckIcon className={classes.activeButton} onClick={StrategyAction.active(row.id)} />*/}
                {/*</TableCell>*/}
                {/*<TableCell>*/}
                {/*  <ClearIcon className={classes.suspendButton} onClick={StrategyAction.suspend(row.id)} />*/}
                {/*</TableCell>*/}
                {/*<TableCell>*/}
                {/*  <DeleteIcon className={classes.deleteButton} onClick={StrategyAction.deleteZ(row.id)} />*/}
                {/*</TableCell>*/}
              </TableRow>
            ))}
        </TableBody>
        <AdminStrategyListFooter />
      </Table>
    </Paper>
  )
}
