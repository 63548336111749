import React from 'react'
import { useStyles } from './style'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { navigate } from 'gatsby'
import { ROUTES } from '../../../constant'
import { useTranslation } from 'react-i18next'

export default function ContentAdminSideMenu() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const adminList = ['strategy', 'signal', 'education']
  return (
    <div className={classes.paper}>
      {adminList.map((record) => {
        return (
          <div key={record}>
            <Divider key={`${record}Divider`} />
            <Typography
              className={classes.listTypo}
              key={`${record}Typography`}
              onClick={() => {
                navigate(`${ROUTES.CONTENT_ADMIN}/${record}`)
              }}
            >
              {record}
            </Typography>
          </div>
        )
      })}
    </div>
  )
}
